<style>
@media (max-width: 575.98px) {
  .altura-mapa {
    height: 300px;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) and (max-width: 767.98px) {
  .altura-mapa {
    height: 300px;
  }
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .altura-mapa {
    height: 300px;
  }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
  .altura-mapa {
    height: 300px;
  }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .altura-mapa {
    height: 300px;
  }
}
</style>
<template>
  <div>
    <div class="bg-top navbar-light">
      <div class="container">
        <div
          class="row no-gutters d-flex align-items-center align-items-stretch"
        >
          <div class="col-md-4 d-flex align-items-center py-4">
            <img
              src="../assets/logo.png"
              height="50px"
              style="cursor: pointer"
              @click="open('/')"
              alt="Tiapps Tecnologia Sistemas Comerciais com Emissão de Nota Fiscal Eletronica e Controle de saldo, Mercadorias e Estoque."
            />
          </div>
          <div class="col-lg-8 d-block">
            <div class="row d-flex">
              <div
                class="col-md d-flex topper align-items-center align-items-stretch py-md-4"
              >
                <div
                  class="icon d-flex justify-content-center align-items-center"
                >
                  <span class="icon-paper-plane"></span>
                </div>
                <div class="text">
                  <span>E-mail</span>
                  <span
                    ><a
                      href="mailto:comercial@tiapps.com.br?subject=Vim pelo site&body=Por favor, gostaria de saber mais a respeito da empresa e dos produtos"
                      class="__cf_email__"
                      data-cfemail="7d0412080f18101c14113d18101c1411531e1210"
                      >comercial@tiapps.com.br</a
                    ></span
                  >
                </div>
              </div>
              <div
                class="col-md d-flex topper align-items-center align-items-stretch py-md-4"
              >
                <div
                  class="icon d-flex justify-content-center align-items-center"
                >
                  <span class="icon-phone2"></span>
                </div>
                <div class="text">
                  <span>Telefone</span>
                  <span>(53) 3238-1145</span>
                </div>
              </div>
              <div
                class="col-md topper d-flex align-items-center justify-content-end"
              >
                <p class="mb-0 d-block">
                  <a href="/#contato" class="btn py-2 px-3 btn-primary">
                    <span>Solicitar Contato!</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <nav
      class="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light"
      id="ftco-navbar"
    >
      <div class="container d-flex align-items-center">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="oi oi-menu"></span> Menu
        </button>
        <div class="collapse navbar-collapse" id="ftco-nav">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a href="/" class="nav-link pl-0">Início</a>
            </li>
            <li class="nav-item"><a href="/blog" class="nav-link">Blog</a></li>
            <!-- <li class="nav-item">
              <a href="/integracoes" class="nav-link">Integrações</a>
            </li> -->
            <li class="nav-item">
              <a href="/quemsomos" class="nav-link">Quem somos</a>
            </li>
            <li class="nav-item">
              <a href="/#linhaguappo" class="nav-link">Linha Guappo</a>
            </li>
            <li class="nav-item">
              <a href="/downloads" class="nav-link">Downloads</a>
            </li>
            <li class="nav-item">
              <a href="/#contato" class="nav-link">Contato</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-view></router-view>
    <footer class="ftco-footer ftco-bg-dark ftco-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-4 col-lg-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.0938927992324!2d-52.044403084161424!3d-32.01257023254546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95119d16097a5221%3A0x223dd4074d5eb4e7!2sTiApps%20Tecnologia!5e0!3m2!1spt-BR!2sbr!4v1626179004244!5m2!1spt-BR!2sbr"
              style="width: 100%"
              class="altura-mapa"
            ></iframe>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="ftco-footer-widget mb-5">
              <h2 class="ftco-heading-2">Nosso endereço</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li>
                    <span class="icon icon-map-marker"></span
                    ><span class="text"
                      >Rua Marechal Floriano, 235 A, Centro, São José do Norte -
                      RS</span
                    >
                  </li>
                  <li>
                    <a
                      ><span class="icon icon-phone"></span
                      ><span class="text"> +55 (53) 3238-1145 </span></a
                    >
                  </li>
                  <li>
                    <a
                      href="mailto:comercial@tiapps.com.br?subject=Vim pelo site&body=Por favor, gostaria de saber mais a respeito da empresa e dos produtos"
                      ><span class="icon icon-envelope"></span
                      ><span class="text"
                        ><span class="__cf_email__"
                          >comercial@tiapps.com.br</span
                        ></span
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4">
            <div class="ftco-footer-widget mb-5 ml-md-4">
              <h2 class="ftco-heading-2">Navegação rápida</h2>
              <ul class="list-unstyled">
                <li>
                  <a href="/"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Início</a
                  >
                </li>
                <li>
                  <a href="/blog"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Blog</a
                  >
                </li>
                <!-- <li>
                  <a href="/integracoes"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Integrações</a
                  >
                </li> -->
                <li>
                  <a href="/quemsomos"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span>Quem
                    somos</a
                  >
                </li>
                <li>
                  <a href="/#linhaguappo"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span>Linha
                    Guappo</a
                  >
                </li>
                <li>
                  <a href="/downloads"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Downloads</a
                  >
                </li>
                <li>
                  <a href="/#contato"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Contato</a
                  >
                </li>
                <li>
                  <a href="/politicas"
                    ><span class="ion-ios-arrow-round-forward mr-2"></span
                    >Centro de privacidade</a
                  >
                </li>
              </ul>
              <h6
                class="your-button-class"
                data-hc-control="content01"
                aria-expanded="false"
                aria-controls="basicContent01"
                @click="openCloseItem()"
                style="cursor: pointer"
              >
                Imagens por Freepik
              </h6>
              <div
                id="basicContent01"
                data-hc-content="content01"
                aria-hidden="false"
              >
                <ul class="list-unstyled">
                  <li>
                    <a href="https://br.freepik.com/fotos/alimento"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Alimento foto criado por drobotdean - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/fotos/cartao-de-visita"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Cartão de visita foto criado por rawpixel.com -
                      br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/psd/computador"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Computador psd criado por freepik - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/psd/computador"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Computador psd criado por rawpixel.com -
                      br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/fotos/fundo"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Fundo foto criado por freepik - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/fotos/fundo"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Fundo foto criado por suksao - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/psd/fundo"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Fundo psd criado por rawpixel.com - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/fotos/maquete"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Maquete foto criado por rawpixel.com - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/psd/maquete"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Maquete psd criado por freepik - br.freepik.com</a
                    >
                  </li>
                  <li>
                    <a href="https://br.freepik.com/fotos/negocio"
                      ><span class="ion-ios-arrow-round-forward mr-2"></span
                      >Negócio foto criado por rawpixel.com - br.freepik.com</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <p>
              © Copyright {{ new Date().getFullYear() }} por Tiapps Tecnologia.
            </p>
          </div>
        </div>
      </div>
    </footer>
    <div id="ftco-loader" class="show fullscreen">
      <svg class="circular" width="48px" height="48px">
        <circle
          class="path-bg"
          cx="24"
          cy="24"
          r="22"
          fill="none"
          stroke-width="4"
          stroke="#eeeeee"
        />
        <circle
          class="path"
          cx="24"
          cy="24"
          r="22"
          fill="none"
          stroke-width="4"
          stroke-miterlimit="10"
          stroke="#F96D00"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import HandyCollapse from "handy-collapse";
const myAccrodion = new HandyCollapse();
export default {
  data() {
    return {
      sendingForm: false,
      model: {
        email: "",
        nome: "",
        telefone: "",
        sobre: "",
        mensagem: "",
      },
      itemOpened: false,
    };
  },
  methods: {
    open(link) {
      window.location.href = link;
    },
    openCloseItem() {
      if (this.itemOpened) {
        this.itemOpened = false;
        myAccrodion.close("content01");
      } else {
        this.itemOpened = true;
        myAccrodion.open("content01");
      }
    },
  },
  mounted() {
    myAccrodion.close("content01");
  },
};
</script>
